<div class="person" id="chat-list-element" data-chatId="{{chatId}}">
  <div class="box">
    <div class="person-box-image">
      <svg height="47" viewbox="0 0 47 47" width="47" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23.5" cy="23.5" fill="#EFEFEF" r="23.5"></circle></svg>
    </div>
  </div>
  <div class="information">
    <div class="content">
      <div class="person-information-username">
        {{title}}
      </div>
      <div class="person-information-message">
        {{last_message}}
      </div>
    </div>
  </div>

  <div class="notice">
    <div class="person-notice-data">
      <time class="person-notice-data-time">
          {{time}}
      </time>
    </div>
    {{#if unread_count}}
    <div class="person-notice-point">
      {{unread_count}}
    </div>
  </div>
  {{/if}}
</div>