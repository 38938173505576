<div class="chat-content-header">
    <div class="chat-content-header-person">
        <div style="display: none" class="chat-content-header-person-box">
            <div class="image">
                <svg height="47" viewbox="0 0 47 47" width="47" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="23.5" cy="23.5" fill="#EFEFEF" r="23.5"></circle></svg>
            </div>
        </div>
        <div class="chat-content-header-person-information">
            <div class="content">
                <div class="username">
                    {{currentChatName}}
                </div>
            </div>
        </div>
        <div class="chat-content-header-person-notice">
            <div class="burger-menu">
                <svg height="16" viewbox="0 0 3 16" width="3" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1.5" cy="2" fill="#1E1E1E" r="1.5"></circle>
                    <circle cx="1.5" cy="8" fill="#1E1E1E" r="1.5"></circle>
                    <circle cx="1.5" cy="14" fill="#1E1E1E" r="1.5"></circle></svg>
            </div>
        </div>
    </div>
</div>