<div>
<div class="main">
  <section class="hero" id="hero">
    <div class="chat">
      <div class="top">
        <div class="chat-top-profil">
          <a class="chat-top-profil-link" href="settings">Профиль <img alt="" src="{{toRightAngleSvg}}"></a>
        </div>
        <div class="chat-top-search-add">
            <div class="chat-top-search">
              <form class="chat-forma">
                {{{chatsSearchInput}}}
              </form>
          </div>
            {{{chatAddBlock}}}
        </div>

      </div>

      <div class="tumbler">
        <div class="conversations">
          {{#each chatsListBlocks}}
            {{{ this }}}
          {{/each}}
        </div>
      </div>
    </div>
      {{{currentChatBlock}}}
  </section>
</div>
{{{newChatModalBlock}}}
{{{burgerMenuModalBlock}}}
</div>