{{#if isIn}}
<div class="chat-content-holder-message-holder-message-in">
  <div class="message-in-text">
    <p class="chat-content-holder-message-holder-message-in-text-p">{{text}}</p>
    <time class="chat-content-holder-message-holder-message-in-text-time">
      {{time}}
    </time>
  </div>
</div>
{{else}}
<div class="message-out">
  <div class="chat-content-holder-message-holder-message-out-text">
    <p class="chat-content-holder-message-holder-message-out-text-p">{{text}}</p>
    <time class="chat-content-holder-message-holder-message-out-text-time-check">
      <span class="mark-polucil"><svg height="5" viewbox="0 0 11 5" width="11" xmlns="http://www.w3.org/2000/svg">
      <line stroke="#3369F3" transform="matrix(0.705933 0.708278 -0.705933 0.708278 0.700195 2.33313)" x2="3.765" y1="-0.5" y2="-0.5"></line>
      <line stroke="#3369F3" transform="matrix(0.705933 -0.708278 0.705933 0.708278 3.35828 5.00006)" x2="5.6475" y1="-0.5" y2="-0.5"></line>
      <line stroke="#3369F3" transform="matrix(0.705933 -0.708278 0.705933 0.708278 6.01587 5.00006)" x2="5.6475" y1="-0.5" y2="-0.5"></line></svg></span>{{time}}
    </time>
  </div>
</div>
{{/if}}