<div>
	<div class="main">
		<section class="profile">
			{{{profileSidebarBlock}}}
			<div class="profile-content">
				<form class="profil-forma">
					{{{profileAvaBlock}}}
				  	<h1>{{first_name}} {{second_name}}</h1>

					<div class="prof-table d-flex">
	          {{#each profileRowBlock}}
	            {{{ this }}}
	          {{/each}}
					</div>
					<div class="prof-table d-flex data-forma">
			          {{#each profileChangeLinkBlock}}
			            {{{ this }}}
			          {{/each}}
					</div>
					<div class="out-profil">{{{logoutLink}}}</div>
				</form>	
			</div>
		</section>
	</div>
	{{{profileAvaChangeModalBlock}}}
</div>